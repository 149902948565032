<template>
    <el-card class="box-card">
        <div class="block">
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="current_page"
                :page-sizes="this.$store.state.pageSizes"
                :page-size="page_size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </div>
    </el-card>
</template>

<script>
export default {
    name: 'CommonPageComponent',
    data() {
        return {}
    },
    computed: {},
    methods: {
        handleSizeChange(val) {
            this.$emit('search', { limit: val })
        },
        handleCurrentChange(val) {
            this.$emit('search', { page: val })
        },
    },
    filters: {},
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    props: {
        current_page: Number,
        page_size: Number,
        total: Number,
    },
}
</script>

<style lang='less'></style>