import request from '../request'

//装卸平台列表
export const terrace_index_request = p => {
    return request({
        method:'GET',
        url:'terrace/index',
        params: p
    })
}

//添加装卸平台
export const terrace_add_request = d => {
    return request({
        method:'POST',
        url:'terrace/add',
        data: d
    })
}

//删除装卸平台
export const terrace_del_request = id => {
    return request({
        method:'DELETE',
        url:'terrace/del',
        data: {
            id: id
        }
    })
}

//修改装卸平台
export const terrace_edit_request = d => {
    return request({
        method:'PUT',
        url:'terrace/edit',
        data: d
    })
}

//获取装卸平台详情
export const terrace_details_request = id => {
    return request({
        method:'GET',
        url:'terrace/details',
        params: {
            id
        }
    })
}